import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import BasicLayout from 'src/components/layouts/BasicLayout';
import { H1, A, Strong, prettyColors, getFaintIconColorClass } from 'src/DesignSystem';
import { Link } from 'gatsby';
import matter from 'gray-matter';
import ReactMarkdown from 'react-markdown';
import SEO from 'src/components/SEO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faDragon, faDiceD20 } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

interface Node {
  node: {
    id: string;
    internal: {
      content: string;
    };
    frontmatter: {
      date: string;
      title: string;
      slug: string;
      indexed?: boolean;
    };
  };
}

interface Props {
  data: {
    allMdx: {
      edges: Node[];
    };
  };
}

export default function Articles(props: Props): JSX.Element {

  // Remove articles that shouldn't be indexed
  const renderedArticles = useMemo(() => {
    return props.data.allMdx.edges.filter((post: Node) => post.node.frontmatter.indexed !== false);
  }, [props.data]);

  return (
    <BasicLayout>
      <SEO title="Articles" description="Various articles and RPG rule thoughts" />
      <div className="max-w-3xl p-4 mx-auto text-gray-900 mb-8">
        <span className="relative">
          <div className="absolute bg-purple-200 h-6 w-full rounded top-0 z-0" />
          <H1 className="relative inline z-10 mx-2">Articles</H1>
        </span>
        <ul>
          {renderedArticles.map((post: Node) => {
            const content = matter(post.node.internal.content, {
              excerpt: true,
              // eslint-disable-next-line @typescript-eslint/camelcase
              excerpt_separator: '<!-- end -->',
            });
            return (
              <li key={post.node.id} className="border-b-2 py-4">
                <span className="italic text-gray-500">{post.node.frontmatter.date}</span>
                <Link to={post.node.frontmatter.slug}>
                  <h2 className="text-lg font-medium mb-2 mt-1">{post.node.frontmatter.title}</h2>
                </Link>
                {content.excerpt ? (
                  <ReactMarkdown
                    className="text-gray-600 mb-2"
                    renderers={{
                      link: A,
                      strong: Strong,
                    }}
                  >
                    {content.excerpt}
                  </ReactMarkdown>
                ) : (
                  <div className="text-center">
                    {prettyColors.map((color, index) => (
                      <FontAwesomeIcon
                        className={clsx('mr-2', 'text-xl', getFaintIconColorClass(color))}
                        icon={index % 2 ? faDragon : faDiceD20}
                        key={color}
                      />
                    ))}
                  </div>
                )}

                <div className="text-right">
                  <Link
                    className={clsx(
                      'py-1',
                      'px-2',
                      'mr-2',
                      'rounded',
                      'font-medium',
                      'text-purple-600',
                      'hover:bg-purple-100'
                    )}
                    to={post.node.frontmatter.slug}
                  >
                    Read full article
                    <FontAwesomeIcon className="ml-2" icon={faLongArrowAltRight} />
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </BasicLayout>
  );
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { slug: { glob: "/articles/*" } } }
    ) {
      edges {
        node {
          id
          internal {
            content
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            slug
            indexed
          }
        }
      }
    }
  }
`;
